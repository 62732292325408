export interface IState {
	person: IPerson[],
	show_email: string,
	show_phonenumber: string,
	layout: string,
	bg_color: string,
}

export interface IPerson {
	name: string,
	page_url: string,
	image_url: string,
	image_html: string,
	phone: string,
	email: string,
	title: string,
	assignments: Array<string>
}

const defaultState: IState = {
	person: [],
	show_email: 'true',
	show_phonenumber: 'true',
	layout: 'row',
	bg_color: '#CCCCCC'
}

export default defaultState;
