



























import Vue from 'vue'
import { IPerson } from '../store/state';

interface ContactCardProps {
	person: IPerson;
}

// define computed properties for this component
interface ContactCardComputed {
	showEmail: boolean,
	showPhoneNumber: boolean,
	layout: string,
	bgColor: string,
	objectFitCompatible: boolean
}

export default Vue.extend<{}, ContactCardProps, ContactCardComputed, {}>({
	name:'contact-card',
	props: {
		person: {},
	},
	computed: {
		showEmail () { return this.$store.getters.show_email },
		showPhoneNumber () { return this.$store.getters.show_phonenumber },
		layout() { return this.$store.getters.layout },
		bgColor() { return this.$store.getters.bg_color },
		objectFitCompatible(){
			return ('objectFit' in document.documentElement.style === true);
		}
	}
})
