import { Getter } from "vuex";
import { IState } from './state';

const show_email: Getter<IState, IState> = (state) => state.show_email == 'true';
const show_phonenumber: Getter<IState, IState> = (state) => state.show_phonenumber == 'true';
const layout: Getter<IState, IState> = (state) => state.layout;
const bg_color: Getter<IState, IState> = (state) => state.bg_color;
const person: Getter<IState, IState> = (state) => state.person;

export default {
	show_email,
	show_phonenumber,
	layout,
	bg_color,
	person
}
