






import Vue from 'vue'
import ContactCard from './components/ContactCard.vue';
import { IPerson } from './store/state';

// Interfaces for component typechecking!
interface AppData {

}

interface AppMethods {

}

interface AppComputed {
	person: IPerson[];
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	computed: {
		person () { 
			console.log(this.$store.getters.person)
			return this.$store.getters.person
		}
	},
	components: {
		ContactCard
	}
})
